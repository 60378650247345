import NavBar from "../../navigation/NavBar"
import React from "react";
import { useParams } from "react-router-dom"
import {
    useExerciseArtefacts,
    useExerciseAttempts,
    useExerciseResultOverview
} from "../queries"
import { TraceElementList } from "./ExerciseResultView";
import './ExerciseResultOverview.css'

export default function ExerciseResultOverview() {
    const { exerciseId } = useParams()
    const [isOverview, setIsOverview] = React.useState(true)
    const { isLoading: submissionsIsLoading, data: submissions } = useExerciseAttempts(exerciseId)
    const { isLoading: overviewIsLoading, data: overview } = useExerciseResultOverview(exerciseId)
    const { isLoading: artefactsLoading, data: artefacts } = useExerciseArtefacts(exerciseId)


    return (
        <>
            <NavBar account={ true } projects={ true } projectsSettings={ false }/>
            <div className='overview-nav-bar'>
                <div>
                    <div className={ isOverview ? 'selected' : '' }
                         onClick={ () => setIsOverview(true) }>Overview
                    </div>
                    <div className={ !isOverview ? 'selected' : '' }
                         onClick={ () => setIsOverview(false) }>Submissions
                    </div>
                </div>
            </div>
            {
                isOverview ? <div className='overview-container'>
                        { !(overviewIsLoading || artefactsLoading) &&
                            <div className="overview">
                                <h2>Overview</h2>
                                <div className="overview-labels">
                                    <div>Source</div>
                                    <div className='end'>Target</div>
                                    <div className='end'>Correct submissions</div>
                                </div>
                                <TraceElementList
                                    sourceArtefacts={ artefacts.sourceArtefacts }
                                    targetArtefacts={ artefacts.targetArtefacts }
                                    traces={ overview }
                                    isOverview={ true }
                                />
                            </div>
                        }
                    </div> :
                    <>
                        { !submissionsIsLoading && submissions.eExerciseAttempts.length > 0 &&
                            <div className="results">
                                <h2>Submissions</h2>
                                { submissions.eExerciseAttempts.map(attempt => (
                                    <ExerciseResultItem
                                        key={ attempt.id }
                                        attempt={ attempt }
                                        exerciseId={ exerciseId }
                                        exerciseName={ submissions.name }
                                    />
                                )) }
                            </div>
                        }
                        { !submissionsIsLoading && submissions.eExerciseAttempts.length === 0 &&
                            <div className="results not-available">
                                <h3>There are no submissions for the selected exercise as of now.</h3>
                            </div>
                        }
                    </>
            }

        </>
    )
}

function ExerciseResultItem({ attempt, exerciseId, exerciseName }) {
    return (
        <div className='exercise-attempt-result-item'>
            <a href={ `/exercise/${ exerciseId }/result/${ attempt.id }` }>
                <span>By:</span> <span>{ attempt.User.firstname } { attempt.User.lastname }</span>
                <span>Started:</span> <span>{ attempt.createdAt }</span>
                <span>Submitted:</span> <span>{ attempt.completed }</span>
                <span>Exercise name:</span> <span>{ exerciseName }</span>
            </a>
        </div>
    )
}
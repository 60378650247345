import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { createExercise } from "../../exercise/queries";

export default function CreateExerciseModal({ show, onHide }) {
    const { projectId } = useParams()
    const navigate = useNavigate()
    const [emails, setEmails] = React.useState([])
    const [exerciseName, setExerciseName] = React.useState('')
    const [email, setEmail] = React.useState('')

    async function onCreate() {
        console.log({ exerciseName })
        if (exerciseName) {
            const response = await createExercise(projectId, exerciseName, emails)
            console.log(response)
            if (response.status === 200) {
                navigate('/exercise')
            }
        }
    }

    function addEmail() {
        setEmails([...emails, email])
    }


    return (
        <Modal
            show={ show }
            onHide={ onHide }
            size={ 'm' }
            aria-labelledby={ 'contained-modal-title-vcenter' }
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id={ 'contained-modal-title-vcenter' }>
                    Create Exercise
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="modal-input-group">
                    <label htmlFor="class-name">Exercise name:</label>
                    <input
                        type="text"
                        name="exercise-name"
                        placeholder="Exercise name"
                        value={ exerciseName }
                        onChange={ (e) => setExerciseName(e.target.value) }
                    />
                </div>
                <div className="modal-input-group">
                    <label htmlFor="class-name">Allowed users:</label>
                    <div className="modal-button-row">
                        <input
                            type="text"
                            name="exercise-name"
                            placeholder="Email"
                            value={ email }
                            onChange={ (e) => setEmail(e.target.value) }
                        />
                        <button onClick={ addEmail }>Add</button>
                    </div>
                    <ul>
                        { emails.map(email => (
                            <li>{ email }</li>
                        )) }
                    </ul>
                </div>
                <div className="modal-button-row">
                    <button onClick={ onHide }>Cancel</button>
                    <button onClick={ onCreate }>Save</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
import './DetailsBanner.css'
import { useEffect, useState } from 'react'
import UserStoryList from './userStoryList/UserStoryList'
import ArtefactDetails from './artefactDetails/ArtefactDetails'
import SelectedUserStory from './selectedUserStory/SelectedUserStory'

export default function DetailsBanner({ userStories, selectedArtefact, entities, entityColors, setSelectedArtefact }) {
    const [filteredStories, setFilteredStories] = useState([])
    const [selectedUserStory, setSelectedUserStory] = useState([])

    useEffect(() => {
        console.log({ userStories, selectedArtefact })
        const stories = userStories.filter(us => {
            if (selectedArtefact.type === 'US') {
                return us.id === selectedArtefact.usId
            } else {
                const ids = selectedArtefact.instances.map(i => i.mainArtefactId)
                return ids.includes(us.id)
            }
        })
        setFilteredStories(stories)
        if (selectedArtefact.type === 'US') {
            setSelectedUserStory(selectedArtefact)
        } else {
            setSelectedUserStory(stories[0])
        }
    }, [selectedArtefact])


    return (
        <div className="details-container">
            <div className="details-user-stories">
                <UserStoryList
                    userStories={ filteredStories }
                    selectedUserStory={ selectedUserStory }
                    setSelectedUserStory={ setSelectedUserStory }
                />
            </div>
            <div className="details-selected-user-story-container">
                <SelectedUserStory
                    setSelectedArtefact={ setSelectedArtefact }
                    userStory={ selectedUserStory }
                    entities={ entities }
                    selectedArtefact={ selectedArtefact }
                    entityColors={ entityColors }
                />
            </div>
            <div className="details-selected-artefact-container">
                <ArtefactDetails
                    artefact={ selectedArtefact }
                    selectedUserStory={ selectedUserStory }
                    classes={ entities.filter(e => e.type === 'ACTOR' || e.type === 'CLASS') }
                />
            </div>
        </div>
    )
}
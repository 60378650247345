import NavBar from "../../navigation/NavBar";
import React, { useState } from "react";
import './ExerciseAttemptView.css'
import { useParams } from "react-router-dom";
import { useExerciseArtefacts, useExerciseTraces } from "../queries";
import { Spinner } from "react-bootstrap";
import UserStoryMenu from "../../tracing/userStoryMenu/UserStoryMenu";
import ExerciseAttemptContent from "./ExerciseAttemptContent";
import TraceSideBar from "./TraceSideBar";

export default function ExerciseAttemptView() {
    const { exerciseId, attemptId } = useParams()
    const { isLoading: artefactsLoading, data: artefacts } = useExerciseArtefacts(exerciseId)
    const { isLoading: tracesLoading, data: traces } = useExerciseTraces(exerciseId, attemptId)
    const [selectedUserStory, setSelectedUserStory] = useState(undefined)
    const [selectedSourceArtefacts, setSelectedSourceArtefacts] = useState([])
    const [selectedTargetArtefact, setSelectedTargetArtefact] = useState(undefined)

    function setUserStory(us) {
        setSelectedSourceArtefacts([])
        setSelectedUserStory(us)
    }

    return (
        <>
            <NavBar
                account={ true }
                projects={ true }
                projectsSettings={ false }
            />
            { (artefactsLoading || tracesLoading) ?
                <div className="loading-container">
                    <Spinner animation="grow"/>
                </div> :
                <div className="container">
                    <div className="user-story-menu">
                        <UserStoryMenu userStories={ artefacts.sourceArtefacts }
                                       selectedArtefact={ selectedUserStory }
                                       setSelectedArtefact={ setUserStory }
                                       showCheckBox={ false }
                                       getName={ (us) => `User Story ${ us.uniqueId }` }
                                       getContent={ (us) => us.content }
                                       traces={ traces }
                        />
                    </div>
                    <div className="content">
                        <ExerciseAttemptContent
                            selectedUserStory={ selectedUserStory }
                            selectedSourceArtefacts={ selectedSourceArtefacts }
                            setSelectedSourceArtefacts={ setSelectedSourceArtefacts }
                            targetArtefacts={ artefacts.targetArtefacts }
                            selectedTargetArtefact={ selectedTargetArtefact }
                            setSelectedTargetArtefact={ setSelectedTargetArtefact }
                            traces={ traces }
                        />
                    </div>
                    <div className="actions">
                        <TraceSideBar
                            selectedUserStory={ selectedUserStory }
                            sourceArtefacts={ artefacts.sourceArtefacts }
                            selectedSourceArtefacts={ selectedSourceArtefacts }
                            setSelectedSourceArtefacts={ setSelectedSourceArtefacts }
                            targetArtefacts={ artefacts.targetArtefacts }
                            selectedTargetArtefact={ selectedTargetArtefact }
                            setSelectedTargetArtefact={ setSelectedTargetArtefact }
                            saveTrace={ () => null }
                        />
                    </div>
                </div>
            }

        </>
    )
}
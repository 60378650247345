import * as d3 from "d3";
import { EntityType } from "../../Types";
import { dragEnded, dragged, dragStarted, handleZoom } from "../../d3Util";

export function buildGraph(ref, nodes, links, userStories, entityColors,
                           setSelectedArtefact = (artefact) => null,
                           setModalArtefact = (artefact) => null,
                           setShowDeleteModal = (artefact) => null,
                           setModalUserStory = (artefact) => null,
                           setShowAddClassModal = (artefact) => null,
                           setShowAddAttributeModal = (artefact) => null,
                           setShowAddRelationshipModal = (artefact) => null,
                           addInteractivity = true,
                           width = 1920,
                           height = 1080
) {
    function showTooltip(event, node) {
        const tooltip = d3.select('div.container')
            .append('div')
            .attr('id', 'tooltip')
            .style('left', (event.pageX + 10) + 'px')
            .style('top', (event.pageY - 15) + 'px')

        const usIds = node.instances ? node.instances.map(i => i.mainArtefactId) : [node.usId]

        tooltip.html(`
            <div>Name:</div>
            <div>${ node.name ? node.name : node.usId }</div>
            <div>Type:</div>
            <div style="text-transform: lowercase">${ node.type }</div>
            <div>User Stories:</div>
            <div>${
            userStories.filter(us => usIds.includes(us.id))
                .map(us => us.uniqueUserStoryProjectId)
                .reduce((a, b) => `${ a }, ${ b }`)
        }</div>
            `)

    }

    function hideTooltip() {
        d3.selectAll('div#tooltip')
            .remove()
    }

    function hideMenu() {
        d3.selectAll('div#menu')
            .remove()
    }

    function showMenu(event, node) {
        hideMenu()
        hideTooltip()
        const menu = d3.select('div.container')
            .append('div')
            .attr('id', 'menu')
            .style('left', (event.pageX + 10) + 'px')
            .style('top', (event.pageY - 15) + 'px')

        menu.append('div')
            .text('Details')
            .on('click', () => {
                setSelectedArtefact(node)
                hideMenu()
            })

        menu.append('div')
            .text('Delete node')
            .on('click', () => deleteNode(node))

        if (node.type === EntityType.CLASS || node.type === EntityType.ACTOR) {
            menu.append('div')
                .text('Add attribute')
                .on('click', () => addAttribute(node))
            menu.append('div')
                .text('Add relationship')
                .on('click', () => addRelationship(node))
        }

        if (node.type === EntityType.US) {
            menu.append('div')
                .text('Add class')
                .on('click', () => addClass(node))
        }
    }

    function deleteNode(node) {
        hideMenu()
        setModalArtefact(node)
        setShowDeleteModal(true)
    }

    function addClass(node) {
        hideMenu()
        setModalArtefact(node)
        setModalUserStory(node)
        setShowAddClassModal(true)
    }

    function addAttribute(node) {
        hideMenu()
        setModalArtefact(node)
        setModalUserStory(userStories.find(us => us.id === node.instances[0].mainArtefactId))
        setShowAddAttributeModal(true)
    }

    function addRelationship(node) {
        hideMenu()
        setModalArtefact(node)
        setModalUserStory(userStories.find(us => us.id === node.instances[0].mainArtefactId))
        setShowAddRelationshipModal(true)
    }

    function ticked() {
        link
            .attr('x1', d => d.source.x)
            .attr('y1', d => d.source.y)
            .attr('x2', d => d.target.x)
            .attr('y2', d => d.target.y)

        node
            .attr('cx', d => d.x)
            .attr('cy', d => d.y)

        text
            .attr('x', d => d.x)
            .attr('y', d => d.y)
    }

    function getNodeLabel(node) {
        switch (node.type) {
            case EntityType.US:
                return `US${ node.uniqueUserStoryProjectId }`
            case EntityType.ACTOR:
                return `A${ node.id }`
            case EntityType.CLASS:
                return `C${ node.id }`
            case EntityType.RELATIONSHIP:
                return `RS${ node.id }`
            case EntityType.ATTRIBUTE:
                return `AT${ node.id }`
        }
    }

    const simulation = d3.forceSimulation(nodes)
        .force('link', d3.forceLink(links).id(d => d.id).distance(80))
        .force('charge', d3.forceManyBody().strength(-5))
        .force('center', d3.forceCenter((width / 2), (height / 2)))
        .on('tick', ticked)

    d3.select(ref.current)
        .selectAll('*')
        .remove()

    const svg = d3.select(ref.current)
        .attr('width', '100%')
        .attr('height', 'auto')
        .attr('viewBox', [0, 0, width, height])

    const link = svg.append('g')
        .attr('stroke', '#656565')
        .attr('stroke-opacity', 0.6)
        .selectAll('line')
        .data(links)
        .join('line')
        .attr('stroke-width', 1)

    const nodeGroup = svg.append('g')
        .selectAll('g')
        .data(nodes)
        .enter()
        .append('g')

    const node = nodeGroup
        .append('circle')
        .attr('r', 15)
        .attr('fill', d => entityColors[d.type])

    const text = nodeGroup
        .append('text')
        .text(node => getNodeLabel(node))
        .attr('font-size', '8px')
        .attr('font-weight', 'lighter')
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('stroke', '#000')

    if (addInteractivity) {
        svg.on('click', hideTooltip)
            .on('click', hideMenu)
            .on('contextmenu', (e) => e.preventDefault())
            .call(d3.zoom()
                .on('zoom', handleZoom))

        nodeGroup.on('mouseenter', (e, node) => showTooltip(e, node))
            .on('mouseout', () => hideTooltip())
            .on('contextmenu', (e, node) => showMenu(e, node))
            .on('click', (e, node) => setSelectedArtefact(node))
            .call(d3.drag()
                .on('start', (e) => dragStarted(e, simulation))
                .on('drag', dragged)
                .on('end', (e) => dragEnded(e, simulation)))

        text.on('mouseenter', (e, node) => showTooltip(e, node))
            .on('mouseout', () => hideTooltip())
    }
}
import './UserStoryStatus.css'
import { EntityType } from "../../tracing/Types";
import { entityColors } from "../../tutorial/tutorialData";
import { useEffect, useState } from "react";

export default function UserStoryStatus({ userStory, traces }) {
    const [hasActor, setHasActor] = useState(false)
    const [hasClass, setHasClass] = useState(false)
    const [hasRelationship, setHasRelationship] = useState(false)

    useEffect(() => {
        setHasActor(isCompleted(EntityType.ACTOR, traces))
        setHasClass(isCompleted(EntityType.CLASS, traces))
        setHasRelationship(isCompleted(EntityType.RELATIONSHIP, traces))
        console.log({ hasActor, hasClass, hasRelationship })
    }, [traces])

    function isCompleted(type, traces) {
        console.log({ type, traces })
        const fragmentIds = userStory.eUserStoryFragments.map(f => f.id)
        const typeTraces = traces.filter(trace => trace.eEntity.type === type)
        for (const trace of typeTraces) {
            for (const sa of trace.eSourceArtefacts) {
                if (fragmentIds.includes(sa.userStoryFragmentId)) {
                    return true
                }
            }
        }
        return false
    }

    return (
        <div className='user-story-status'>
            <div className="actor" title='Actor'
                 style={ {
                     borderColor: `${ entityColors[EntityType.ACTOR] }`,
                     background: `${ hasActor ? entityColors[EntityType.ACTOR] : '#FFFFFF00' }`
                 } }
            ></div>
            <div className="class" title='Class'
                 style={ {
                     borderColor: `${ entityColors[EntityType.CLASS] }`,
                     background: `${ hasClass ? entityColors[EntityType.CLASS] : '#FFFFFF00' }`
                 } }
            ></div>
            <div className="relationship" title='Relationship'
                 style={ {
                     borderColor: `${ entityColors[EntityType.RELATIONSHIP] }`,
                     background: `${ hasRelationship ? entityColors[EntityType.RELATIONSHIP] : '#FFFFFF00' }`
                 } }
            ></div>
        </div>
    )
}

export function ColourLegend() {
    return (
        <div className="indicator-legend">
            <div className="legend-item">
                <div className="legend-text">Actor</div>
                <div className="legend-icon" style={ { borderColor: entityColors[EntityType.ACTOR] } }></div>
            </div>
            <div className="legend-item">
                <div className="legend-text">Class</div>
                <div className="legend-icon" style={ { borderColor: entityColors[EntityType.CLASS] } }></div>
            </div>
            <div className="legend-item">
                <div className="legend-text">Relationship</div>
                <div className="legend-icon" style={ { borderColor: entityColors[EntityType.RELATIONSHIP] } }></div>
            </div>
        </div>
    )
}
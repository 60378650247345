/**
 * @readonly
 * @enum {string}
 */
export const EntityType = {
    CLASS: 'CLASS',
    ACTOR: 'ACTOR',
    RELATIONSHIP: 'RELATIONSHIP',
    ATTRIBUTE: 'ATTRIBUTE',
    US: 'US',
}

/**
 * @readonly
 * @enum {string}
 */
export const ViewType = {
    GRAPH: 'GRAPH',
    CLASS_DIAGRAM: 'CLASS_DIAGRAM',
    MATRIX: 'MATRIX',
    LIST: 'LIST',
}
import React, { useEffect, useRef, useState } from "react";
import './TutorialContent.css'
import {
    graphData,
    entityColors,
    selectableSourceArtefact,
    selectableTargetArtefact,
    subArtefacts,
    dmNodes,
    dmLinks,
    showNextButton,
    userStory
} from "./tutorialData";
import SubArtefactPicker from "./SubArtefactPicker";
import { buildClassDiagram } from "../tracing/visualizations/classDiagramView/buildClassDiagram";
import { EntityType } from "../tracing/Types";
import { UserStoryMenuItem } from "../tracing/userStoryMenu/UserStoryMenu";
import TraceSideBar from "../exercise/attempt/TraceSideBar";
import { ColourLegend } from "../exercise/attempt/UserStoryStatus";


export default function TutorialContent({ currentStep, setCurrentStep }) {
    const [selectedArtefacts, setSelectedArtefacts] = useState([])
    const [selectedTargetArtefact, setSelectedTargetArtefact] = useState(null)


    useEffect(() => {
        if (currentStep === 16) {
            if (allArtefactsSelected(currentStep))
                setCurrentStep(currentStep + 1);
        }
    }, [selectedArtefacts])

    function allArtefactsSelected(step) {
        if (JSON.stringify(selectedArtefacts.sort()) !== JSON.stringify(selectableSourceArtefact[step].ids.sort())) return false
        return selectedTargetArtefact === selectableTargetArtefact[step].ids[0]
    }

    useEffect(() => {
        if (currentStep === 10) {
            if (selectedArtefacts.length > 0) {
                setCurrentStep(currentStep + 1)
            }
        }
        if (currentStep === 25 && allArtefactsSelected(25)) {
            setCurrentStep(currentStep + 1)

        }
    }, [selectedArtefacts, currentStep]);

    useEffect(() => {
        if (currentStep === 13) {
            if (selectedTargetArtefact !== null) {
                setCurrentStep(currentStep + 1)
            }
        }
        if (currentStep === 24) {
            if (selectedTargetArtefact !== null) {
                setCurrentStep(currentStep + 1)
            }
        }
    }, [selectedTargetArtefact, currentStep]);

    useEffect(() => {
        if (currentStep === 15) {
            setSelectedTargetArtefact(1)
            setSelectedArtefacts([2, 3])
        }
        if (currentStep === 17) {
            setSelectedTargetArtefact(null)
            setSelectedArtefacts([])
        }
    }, [currentStep]);

    return (
        <div className='single-column'>
            <div className='left-column'>
                { currentStep === 3 &&
                    <div className='user-story-text'>
                        As an Aircraft Manager, I want to create an aircraft so that the company can store all existing
                        aircraft in the system.
                    </div>
                }
                { currentStep === 4 &&
                    <div className='user-story-split'>
                        <div className='user-story-label'>role</div>
                        <div>As an Aircraft Manager</div>
                        <div className='user-story-label'>ends</div>
                        <div>I want to create an aircraft</div>
                        <div className='user-story-label'>means</div>
                        <div>so that the company can store all existing aircraft in the system</div>
                    </div>
                }
                { (currentStep === 5 || currentStep === 6) &&
                    <div className='user-story-split'>
                        <div className='user-story-label'>
                            <span style={ {
                                borderWidth: currentStep === 6 ? '2px' : '0px',
                                borderColor: entityColors[EntityType.ACTOR],
                                borderStyle: 'solid'
                            } }>actor</span>
                        </div>
                        <div>Aircraft Manager</div>
                        <div className='user-story-label'>
                            <span style={ {
                                borderWidth: currentStep === 6 ? '2px' : '0px',
                                borderColor: entityColors[EntityType.CLASS],
                                borderStyle: 'solid'
                            } }>class</span>
                        </div>
                        <div>aircraft</div>
                        <div className='user-story-label'>
                            <span style={ {
                                borderWidth: currentStep === 6 ? '2px' : '0px',
                                borderColor: entityColors[EntityType.RELATIONSHIP],
                                borderStyle: 'solid'
                            } }>relationship</span>
                        </div>
                        <div>Aircraft Manager { '<-' } <strong>create</strong> { '->' } aircraft</div>
                    </div>
                }
                { currentStep > 6 &&
                    <InteractiveView
                        currentStep={ currentStep }
                        setCurrentStep={ setCurrentStep }
                        selectedArtefacts={ selectedArtefacts }
                        setSelectedArtefacts={ setSelectedArtefacts }
                        selectedTargetArtefact={ selectedTargetArtefact }
                        setSelectedTargetArtefact={ setSelectedTargetArtefact }
                    />
                }
            </div>
        </div>
    )
}

function InteractiveView({
                             currentStep,
                             setCurrentStep,
                             selectedArtefacts,
                             setSelectedArtefacts,
                             selectedTargetArtefact,
                             setSelectedTargetArtefact
                         }) {
    const [traces, setTraces] = useState([])

    useEffect(() => {
        setTraces(graphData[stepMapping[currentStep]])
    }, [currentStep])

    const stepMapping = [
        0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
        0, 0, 1, 1, 2,
        3, 3, 0, 0, 0,
        0, 0, 1, 1, 2,
        2, 4, 5, 5, 5,
    ]

    function getSelectedTargetArtefact(id) {
        const node = dmNodes.find(entity => entity.id === id)
        if (node) return node
        return dmLinks.find(link => link.id === id)
    }

    function createTrace() {
        setSelectedArtefacts([])
        setSelectedTargetArtefact(null)
        setCurrentStep(currentStep + 1)
    }

    return (
        <div className='tutorial-content-interactive-view'>
            <div className='tutorial-side-bar-left'>
                { ((currentStep > 6 && currentStep < 12) || currentStep > 14) &&
                    <>
                        <ColourLegend/>
                        <UserStoryMenuItem
                            userStory={ userStory }
                            selectUserStory={ () => null }
                            getName={ (us) => `User Story ${ us.uniqueId }` }
                            getContent={ (us) => us.content }
                            traces={ traces }
                        />
                    </>
                }
            </div>
            <div className='tutorial-content-center-container'>
                { currentStep > 11 &&
                    <div className="tutorial-content-center-container-target">
                        <DomainModel
                            currentStep={ currentStep }
                            setCurrentStep={ setCurrentStep }
                            selectedTargetArtefact={ selectedTargetArtefact }
                            setSelectedTargetArtefact={ setSelectedTargetArtefact }
                        />
                    </div>
                }
                { ((currentStep > 8 && currentStep < 12) || currentStep > 14) &&
                    <div className='tutorial-content-center-container-source'>
                        <SubArtefactPicker
                            subArtefacts={ subArtefacts }
                            selectableArtefacts={ selectableSourceArtefact[currentStep].ids }
                            selectedArtefacts={ selectedArtefacts }
                            setSelectedArtefacts={ setSelectedArtefacts }
                            traces={ traces }
                            entityColors={ entityColors }
                            userStoryId={ 0 }
                            readonly={ selectableSourceArtefact[currentStep].readonly }
                        />
                    </div>
                }
            </div>
            <div className='tutorial-side-bar-right'>
                { currentStep > 15 &&
                    <TraceSideBar
                        selectedUserStory={ userStory }
                        sourceArtefacts={ [userStory] }
                        selectedSourceArtefacts={ selectedArtefacts }
                        setSelectedSourceArtefacts={ setSelectedTargetArtefact }
                        targetArtefacts={ [] }
                        selectedTargetArtefact={ getSelectedTargetArtefact(selectedTargetArtefact) }
                        setSelectedTargetArtefact={ setSelectedTargetArtefact }
                        onCreate={ createTrace }
                        showSubmitButton={ false }
                    />
                }
            </div>
        </div>
    )
}

function DomainModel({ currentStep, setCurrentStep, selectedTargetArtefact, setSelectedTargetArtefact }) {
    const dmRef = useRef()

    useEffect(() => {
        buildClassDiagram(dmRef, dmNodes, dmLinks, entityColors, selectedTargetArtefact, onClassDiagramClick, false, isMarked, 600, 400)
    }, [currentStep, selectedTargetArtefact]);

    function onClassDiagramClick(node) {
        if (selectableTargetArtefact[currentStep].readonly) return
        if (selectableTargetArtefact[currentStep].ids.includes(node.id)) {
            if (selectedTargetArtefact === node.id) setSelectedTargetArtefact(null)
            else {
                setSelectedTargetArtefact(node.id)
                if (currentStep === 15) setCurrentStep(currentStep + 1)
            }
        }
    }

    function isMarked(node) {
        if (currentStep < 15) return false
        if (currentStep < 22) {
            if (node.id === 1 && currentStep > 16) return true
            if (node.id === 0 && currentStep > 18) return true
            if (node.id === 2 && currentStep > 19) return true
        } else {
            if (node.id === 1 && currentStep > 26) return true
            if (node.id === 0 && currentStep > 28) return true
            if (node.id === 2 && currentStep > 31) return true
        }
    }

    return (
        <div style={ { background: 'white' } }>
            <svg ref={ dmRef }></svg>
        </div>
    )
}
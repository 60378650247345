import './Tutorial.css'
import { useState } from "react";
import TutorialContent from "./TutorialContent";
import { steps, goRightDisabled } from "./tutorialData";
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'
import { useNavigate } from "react-router-dom";

export default function Tutorial() {
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(0)

    function onFinish() {
        navigate('/exercise')
    }

    return (
        <div className='container-tutorial'>
            <button className='tutorial-back-button' disabled={ currentStep === 0 }
                    onClick={ () => setCurrentStep(currentStep - 1) }>
                <div>back</div>
                <div className="chevron">
                    <ChevronLeft/>
                </div>
            </button>
            <div className='tutorial-content'>
                <div className='tutorial-content-top'>
                    <h2>{ steps[currentStep].top }</h2>
                </div>
                <div className='tutorial-content-center'>
                    { !!steps[currentStep].center ?
                        <h2>{ steps[currentStep].center }< /h2> :
                        <TutorialContent currentStep={ currentStep } setCurrentStep={ setCurrentStep }/>
                    }
                </div>
                <div className='tutorial-content-bottom'>
                    <h2>{ steps[currentStep].bottom }</h2>
                    { currentStep === 33 &&
                        <button className='save-trace-button'
                                onClick={ onFinish }
                        >Finish</button>
                    }
                </div>
            </div>
            <button className='tutorial-next-button'
                    disabled={ currentStep === steps.length - 1 || goRightDisabled.includes(currentStep) || (goRightDisabled.includes(currentStep)) }
                    onClick={ () => setCurrentStep(currentStep + 1) }>
                { currentStep < steps.length &&
                    <>
                        <div>next</div>
                        <div className="chevron">
                            <ChevronRight/>
                        </div>
                    </>
                }
            </button>
        </div>
    )
}
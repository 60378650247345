import { useEffect, useRef } from 'react'
import './ClassDiagramView.css'
import { buildClassDiagram } from "./buildClassDiagram";

export default function ClassDiagramView({ entityColors, entities, selectedUserStories, setSelectedArtefact }) {
    const svgRef = useRef()

    useEffect(() => {
        // const filteredEntities = entities.filter(entity => {
        //     if (entity.type === 'ACTOR' || entity.type === 'CLASS' || entity.type === 'RELATIONSHIP') {
        //         if (selectedUserStories.length === 0) return true
        //         const ids = entity.instances.map(i => i.mainArtefactId)
        //         return ids.filter(id => selectedUserStories.includes(id)).length > 0
        //     } else {
        //         return false
        //     }
        // })
        const nodes = entities.filter(entity => entity.type === 'ACTOR' || entity.type === 'CLASS')
        const links = entities.filter(entity => entity.type === 'RELATIONSHIP')
            .flatMap(entity => entity.links.flatMap(link => ([
                {
                    ...entity,
                    source: link.from,
                    target: link.to,
                },
            ])))
        buildClassDiagram(svgRef, nodes, links, entityColors, null, setSelectedArtefact)
    }, [])

    return (
        <svg ref={ svgRef } className="class-diagram"></svg>
    )
}
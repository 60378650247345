import React from 'react'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import Login from './components/accounting/Login'
import Registration from './components/accounting/Registration'
import AccountSettings from './components/accounting/AccountSettings'
import ProjectView from './components/project/ProjectView'
import { PrivateRoute, PublicRoute } from './helpers/PrivateRoute'
import ProjectSettingsView from './components/project/projectSettings/ProjectSettingsView'
import ProductBacklogView from './components/stateContainer/productBacklog/ProductBacklogView'
import { history } from './helpers/history'
import TracingView from './components/tracing/TracingView'
import Tutorial from "./components/tutorial/Tutorial";
import ExerciseView from "./components/exercise/ExerciseView";
import ExerciseAttemptView from "./components/exercise/attempt/ExerciseAttemptView";
import ExerciseResultView from "./components/exercise/result/ExerciseResultView";
import ExerciseResultOverview from "./components/exercise/result/ExerciseResultOverview";

function App() {
    history.navigate = useNavigate()
    history.location = useLocation()
    history.params = useParams()

    return (
        <Routes>
            <Route path={ '/' } element={ <PublicRoute/> }>
                <Route exact path={ '/' } element={ <Login/> }/>
            </Route>
            <Route path={ '/registration' } element={ <PublicRoute/> }>
                <Route path={ '/registration' } element={ <Registration/> }/>
            </Route>
            <Route path={ '/account' } element={ <PrivateRoute/> }>
                <Route path={ '/account' } element={ <AccountSettings/> }/>
            </Route>
            <Route path={ '/project' } element={ <PrivateRoute/> }>
                <Route path={ '/project' } element={ <ProjectView/> }/>
            </Route>
            <Route path={ '/project/:projectId/settings' } element={ <PrivateRoute/> }>
                <Route path={ '/project/:projectId/settings' } element={ <ProjectSettingsView/> }/>
            </Route>
            <Route path={ '/project/new' } element={ <PrivateRoute/> }>
                <Route path={ '/project/new' } element={ <ProjectSettingsView isCreate={ true }/> }/>
            </Route>
            <Route path={ '/project/:projectId' } element={ <PrivateRoute/> }>
                <Route path={ '/project/:projectId' } element={ <ProductBacklogView/> }/>
            </Route>
            <Route path={ '/exercise' } element={ <PrivateRoute/> }>
                <Route path={ '/exercise' } element={ <ExerciseView/> }/>
            </Route>
            <Route path={ '/exercise/:exerciseId/attempt/:attemptId' } element={ <PrivateRoute/> }>
                <Route path={ '/exercise/:exerciseId/attempt/:attemptId' } element={ <ExerciseAttemptView/> }/>
            </Route>
            <Route path={ '/exercise/:exerciseId/results' } element={ <PrivateRoute/> }>
                <Route path={ '/exercise/:exerciseId/results' } element={ <ExerciseResultOverview/> }/>
            </Route>
            <Route path={ '/exercise/:exerciseId/result/:attemptId' } element={ <PrivateRoute/> }>
                <Route path={ '/exercise/:exerciseId/result/:attemptId' } element={ <ExerciseResultView/> }/>
            </Route>
            <Route path={ '/exercise/tutorial' } element={ <PrivateRoute/> }>
                <Route path={ '/exercise/tutorial' } element={ <Tutorial/> }/>
            </Route>
            <Route path={ '/project/:projectId/tracing' } element={ <PrivateRoute/> }>
                <Route path={ '/project/:projectId/tracing' } element={ <TracingView/> }/>
            </Route>
        </Routes>
    )
}

export default (App)

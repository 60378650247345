import './SubArtefactPicker.css'

export default function SubArtefactPicker({
                                              subArtefacts,
                                              traces,
                                              entityColors,
                                              userStoryId,
                                              selectableArtefacts = [],
                                              selectedArtefacts,
                                              setSelectedArtefacts,
                                              readonly = false,
                                              allSelectable = false,
                                              getContent = (item) => item.name
                                          }) {

    function isPicked(subArtefact) {
        return selectedArtefacts
            .includes(subArtefact.id)
    }

    function toggleArtefact(subArtefact) {
        if (readonly) return
        if (isPicked(subArtefact)) {
            setSelectedArtefacts(old => old.filter(psa => psa !== subArtefact.id))
        } else {
            setSelectedArtefacts([...selectedArtefacts, subArtefact.id])
        }
    }

    function isEntity(id) {
        const entity = getEntity(id);
        return !!entity
    }

    function getEntity(id) {
        return traces.find(trace => {
            const fragments = trace.eSourceArtefacts.filter(fragment => fragment.userStoryFragmentId === id)
            return (fragments.length > 0)
        })
    }

    function getEntityColor(id) {
        const entity = getEntity(id)
        if (entity) {
            return entityColors[entity.eEntity.type]
        }
        return 'none'
    }

    return (
        <>
            <div className="sub-artefact-select" style={ { background: "transparent" } }>
                {
                    subArtefacts.map((a, index) => (
                        <span
                            key={ index }
                            style={ { borderColor: getEntityColor(a.id) } }
                            className={ `sub-artefact-item 
                            ${ isPicked(a) ? 'selected' : '' } 
                            ${ (allSelectable || selectableArtefacts.includes(a.id)) ? 'selectable write' : '' }
                            ${ isEntity(a.id) ? 'marked' : '' }` }
                            onClick={ () => (allSelectable || selectableArtefacts.includes(a.id)) && toggleArtefact(a) }
                        >
                            { getContent(a) }
                        </span>
                    ))
                }
            </div>
        </>
    )
};
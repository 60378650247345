import './Filter.css'
import { EntityType } from '../Types'
import 'react-color-palette/css'
import SelectColorModal from '../modals/SelectColorModal'
import { useState } from 'react'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import CreateExerciseModal from "../modals/CreateExerciseModal";

export default function Filter(
    {
        entityColors,
        setEntityColors,
        visibleEntities,
        setVisibleEntities,
    }) {
    const [selectedEntityType, setSelectedEntityType] = useState(null)
    const [showCreateExerciseModal, setShowCreateExerciseModal] = useState(false)

    function toggleEntity(type) {
        setVisibleEntities(old => ({ ...old, [type]: !visibleEntities[type] }))
    }

    function changeColor(color, type) {
        if (color) setEntityColors(old => ({ ...old, [type]: color.hex }))
        setSelectedEntityType(null)
    }

    return (
        <div className="filter-container">
            { selectedEntityType !== null &&
                <SelectColorModal
                    show={ selectedEntityType !== null }
                    onHide={ (color) => changeColor(color, selectedEntityType) }
                    currentColor={ entityColors[selectedEntityType] }
                />
            }
            { showCreateExerciseModal &&
                <CreateExerciseModal
                    show={ showCreateExerciseModal }
                    onHide={ () => setShowCreateExerciseModal(false) }
                />
            }
            <FilterViewItem
                entityType={ EntityType.US }
                entityColors={ entityColors }
                setSelectedEntityType={ setSelectedEntityType }
                visibleEntities={ visibleEntities }
                toggleEntity={ toggleEntity }
                title="User Stories"
            />
            <FilterViewItem
                entityType={ EntityType.CLASS }
                entityColors={ entityColors }
                setSelectedEntityType={ setSelectedEntityType }
                visibleEntities={ visibleEntities }
                toggleEntity={ toggleEntity }
                title="Classes"
            />
            <FilterViewItem
                entityType={ EntityType.ACTOR }
                entityColors={ entityColors }
                setSelectedEntityType={ setSelectedEntityType }
                visibleEntities={ visibleEntities }
                toggleEntity={ toggleEntity }
                title="Actors"
            />
            <FilterViewItem
                entityType={ EntityType.ATTRIBUTE }
                entityColors={ entityColors }
                setSelectedEntityType={ setSelectedEntityType }
                visibleEntities={ visibleEntities }
                toggleEntity={ toggleEntity }
                title="Attributes"
            />
            <FilterViewItem
                entityType={ EntityType.RELATIONSHIP }
                entityColors={ entityColors }
                setSelectedEntityType={ setSelectedEntityType }
                visibleEntities={ visibleEntities }
                toggleEntity={ toggleEntity }
                title="Relationships"
            />
            <button className='create-exercise-button' onClick={ () => setShowCreateExerciseModal(true) }>
                Create Exercise
            </button>
        </div>
    )
}

function FilterViewItem({ entityType, entityColors, setSelectedEntityType, visibleEntities, toggleEntity, title }) {
    return (
        <div className="filter-item">
            <div
                className="color"
                style={ { background: entityColors[entityType] } }
                onClick={ () => setSelectedEntityType(entityType) }
            ></div>
            <span>{ title }</span>
            <label>
                <Toggle
                    defaultChecked={ visibleEntities[entityType] }
                    className='toggle'
                    icons={ false }
                    onChange={ () => toggleEntity(entityType) }/>
            </label>
        </div>
    )
}
import './GraphView.css'
import React, {useEffect, useRef, useState} from 'react'
import ModalGroup from '../../modals/ModalGroup'
import {buildGraph} from "./buildGraph";

function getLinks(entities) {
    const links = []
    links.push(...getUserStoryLinks(entities))
    links.push(...getAttributeLinks(entities))
    links.push(...getRelationshipLinks(entities))
    return links
}

function getUserStoryLinks(entities) {
    const links = []
    for (const entity of entities) {
        for (const instance of entity.instances) {
            links.push({
                source: `US-${instance.mainArtefactId}`,
                target: entity.id,
                type: entity.type,
                name: entity.name,
            })
        }
    }
    return links
}

function getAttributeLinks(entities) {
    const attrs = entities.filter(e => e.type === 'ATTRIBUTE')
    return attrs
        .flatMap(entity => entity.mainEntities.map(me => ({
            source: me.mainEntityId,
            target: entity.id,
            type: entity.type,
            name: entity.name,
        })))
}

function getRelationshipLinks(entities) {
    return entities.filter(e => e.type === 'RELATIONSHIP')
        .flatMap(entity => entity.links.flatMap(link => ([
            {
                source: link.from,
                target: entity.id,
                type: entity.type,
                name: entity.name,
            },
            {
                source: link.to,
                target: entity.id,
                type: entity.type,
                name: entity.name,
            },
        ])))
}

function getNodes(entities, userStories) {
    return [
        ...entities,
        ...userStories.map(us => ({
            ...us,
            id: `US-${us.id}`,
            usId: us.id,
            type: 'US',
        })),
    ]
}

function getVisibleNodes(nodes, visibleEntities, selectedUserStories) {
    const visibleNodes = nodes.filter(node => (visibleEntities[node.type]))
    if (selectedUserStories.length > 0) {
        return visibleNodes.filter(node => {
            if (node.type === 'US') {
                return selectedUserStories.includes(node.usId)
            } else {
                const ids = node.instances.map(i => i.mainArtefactId)
                return ids.filter(id => selectedUserStories.includes(id)).length > 0
            }
        })
    }
    return visibleNodes
}

function getVisibleLinks(links, nodes) {
    const ids = nodes.map(node => node.id)
    return links.filter(link => ids.includes(link.source.id) && ids.includes(link.target.id))
}

export default function GraphView({
                                      selectedUserStories,
                                      visibleEntities,
                                      entityColors,
                                      userStories,
                                      entities,
                                      setSelectedArtefact,
                                  }) {
    const svgRef = useRef()
    const [allNodes, setAllNodes] = useState([])
    const [allLinks, setAllLinks] = useState([])

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showAddClassModal, setShowAddClassModal] = useState(false)
    const [showAddAttributeModal, setShowAddAttributeModal] = useState(false)
    const [showAddRelationshipModal, setShowAddRelationshipModal] = useState(false)
    const [modalArtefact, setModalArtefact] = useState(null)
    const [modalUserStory, setModalUserStory] = useState(null)

    useEffect(() => {
        const nodes = getNodes(entities, userStories)
        const links = getLinks(entities)
        setAllNodes(nodes)
        setAllLinks(links)
        buildGraph(svgRef, nodes, links, userStories, entityColors, setSelectedArtefact)
    }, [entities, userStories, entityColors])

    useEffect(() => {
        const nodes = getVisibleNodes(allNodes, visibleEntities, selectedUserStories)
        const links = getVisibleLinks(allLinks, nodes)
        if (nodes.length || links.length) buildGraph(svgRef, nodes, links, userStories, entityColors, setSelectedArtefact)
    }, [visibleEntities, selectedUserStories])

    return (
        <>
            <ModalGroup
                classes={entities.filter(e => e.type === 'ACTOR' || e.type === 'CLASS')}
                artefact={modalArtefact}
                userStory={modalUserStory}
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                showAddClassModal={showAddClassModal}
                setShowAddClassModal={setShowAddClassModal}
                showAddAttributeModal={showAddAttributeModal}
                setShowAddAttributeModal={setShowAddAttributeModal}
                showAddRelationshipModal={showAddRelationshipModal}
                setShowAddRelationshipModal={setShowAddRelationshipModal}
            />
            <svg ref={svgRef} className="tracing-graph"></svg>
        </>
    )
}

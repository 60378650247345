import './UserStoryMenu.css'
import UserStoryStatus, { ColourLegend } from "../../exercise/attempt/UserStoryStatus";
import React, { useEffect } from "react";

export default function UserStoryMenu({
                                          userStories,
                                          selectedArtefact = null,
                                          setSelectedArtefact,
                                          selectedUserStories = [],
                                          setSelectedUserStories = () => null,
                                          showCheckBox = true,
                                          getName = (us) => `User Story ${ us.uniqueUserStoryProjectId }`,
                                          getContent = (us) => us.userStory,
                                          traces = [],
                                      }) {

    const handleChange = (id) => {
        if (selectedUserStories.includes(id))
            setSelectedUserStories(selectedUserStories.filter(usId => usId !== id))
        else
            setSelectedUserStories(us => [...us, id])
    }

    const selectAll = () => {
        const allIds = userStories.map(us => us.id)
        setSelectedUserStories([...allIds])
    }

    const unselectAll = () => setSelectedUserStories([])

    function selectUserStory(us) {
        setSelectedArtefact(({ ...us, type: "US", usId: us.id }))
    }

    return (
        <div className="user-story-menu-container">
            { showCheckBox ?
                <div className="buttons">
                    <button onClick={ selectAll }>Select all</button>
                    <button onClick={ unselectAll }>Unselect all</button>
                </div> :
                <ColourLegend/>
            }
            <div className="user-story-list">
                { userStories.sort((a, b) => (a.uniqueUserStoryProjectId - b.uniqueUserStoryProjectId))
                    .map(us => (
                        <UserStoryMenuItem
                            key={ us.id }
                            selectUserStory={ selectUserStory }
                            showCheckBox={ showCheckBox }
                            getName={ getName }
                            getContent={ getContent }
                            userStory={ us }
                            traces={ traces }
                            selectedUserStories={ selectedUserStories }
                            selectedArtefact={ selectedArtefact }
                            handleChange={ handleChange }
                        />
                    )) }
            </div>
        </div>
    );
}

export function UserStoryMenuItem({
                                      userStory,
                                      selectUserStory,
                                      showCheckBox,
                                      getName = (us) => `User Story ${ us.uniqueUserStoryProjectId }`,
                                      getContent = (us) => us.userStory,
                                      traces = [],
                                      selectedUserStories,
                                      selectedArtefact,
                                      handleChange
                                  }) {

    return (
        <div
            className={ `user-story-item ${ selectedArtefact && (selectedArtefact.id === userStory.id) ? 'selected' : '' }` }
            onClick={ () => selectUserStory(userStory) }>
            <div>
                <div className="user-story-name">{ getName(userStory) }</div>
                <div className="user-story-content">{ getContent(userStory) }</div>
            </div>
            { showCheckBox ?
                <input
                    type="checkbox"
                    className="user-story-checkbox"
                    checked={ selectedUserStories.includes(userStory.id) }
                    onChange={ () => handleChange(userStory.id) }
                /> :
                <UserStoryStatus
                    userStory={ userStory }
                    traces={ traces }
                />
            }
        </div>
    )
}
import NavBar from "../../navigation/NavBar"
import React from "react"
import { useParams } from "react-router-dom"
import { useExerciseArtefacts, useExerciseAttemptResult } from "../queries"
import { Spinner } from "react-bootstrap"
import './ExerciseResultView.css'

export default function ExerciseResultView() {
    const { exerciseId, attemptId } = useParams()
    const { isLoading: resultLoading, data: result } = useExerciseAttemptResult(exerciseId, attemptId)
    const { isLoading: artefactsLoading, data: artefacts } = useExerciseArtefacts(exerciseId)

    return (
        <>
            <NavBar account={ true } projects={ false } projectsSettings={ false }/>
            { (resultLoading || artefactsLoading) ?
                <div className="loading-container">
                    <Spinner animation="grow"/>
                </div> :
                <div className="exercise-result-content">
                    <ResultSummary correct={ result.correctTraces }
                                   wrong={ result.wrongTraces }
                                   missing={ result.missingTraces }/>
                    <h2>Wrong Traces</h2>
                    <TraceElementList
                        sourceArtefacts={ artefacts.sourceArtefacts }
                        targetArtefacts={ artefacts.targetArtefacts }
                        traces={ result.wrongTraces }
                    />
                    <h2>Missing Traces</h2>
                    <TraceElementList
                        sourceArtefacts={ artefacts.sourceArtefacts }
                        targetArtefacts={ artefacts.targetArtefacts }
                        traces={ result.missingTraces }
                    />
                    <h2>Correct Traces</h2>
                    <TraceElementList
                        sourceArtefacts={ artefacts.sourceArtefacts }
                        targetArtefacts={ artefacts.targetArtefacts }
                        traces={ result.correctTraces }
                    />
                </div>
            }
        </>
    )
}

export function TraceElementList({ sourceArtefacts, targetArtefacts, traces, isOverview = false }) {

    function getSourceArtefact(trace) {
        const sourceIds = trace.sourceArtefactIds.split(',').map(a => Number(a))
        return sourceArtefacts.find(us => hasFragment(us.eUserStoryFragments, sourceIds))
    }

    function hasFragment(fragments, ids) {
        console.log({ fragments, ids })
        return !!fragments.find(f => ids.includes(f.id))
    }

    function getTargetArtefact(trace) {
        return targetArtefacts.find(t => t.id === trace.targetArtefactId)
    }

    return (
        <div className='trace-element-list'>
            { traces.map((trace, index) => (
                <TraceElement
                    key={ index }
                    sourceArtefactIds={ trace.sourceArtefactIds.split(',').map(a => Number(a)) }
                    total={ trace.total }
                    count={ trace.count }
                    isOverview={ isOverview }
                    sourceArtefact={ getSourceArtefact(trace) }
                    targetArtefact={ getTargetArtefact(trace) }
                />
            )) }
        </div>
    )

}

function TraceElement({ sourceArtefact, targetArtefact, sourceArtefactIds, total, count, isOverview }) {

    function getFragments() {
        return sourceArtefact.eUserStoryFragments.filter(f => sourceArtefactIds.includes(f.id))
    }


    return (
        <div className={ `trace-element ${ isOverview ? 'overview' : '' }` }>
            <div>
                { getFragments().map(sa => (
                    <div>
                        "{ sa.content }"
                    </div>
                )) }
                <div>
                    From US{ sourceArtefact.uniqueId }
                </div>
            </div>
            <div className='target-artefact-elements'>
                { targetArtefact.name }
                <br/>
                { targetArtefact.type }
            </div>
            { isOverview && (
                <div className='stats'>
                    <div>{ count } / { total }</div>
                </div>
            ) }
        </div>
    )
}

function ResultSummary({ correct, missing, wrong }) {
    return (
        <div className="summary-content">
            <div>
                Correct traces:
                <br/>
                { correct.length } / { correct.length + missing.length }
            </div>
            <div>
                Wrong traces:
                <br/>
                { wrong.length }
            </div>
            <div>
                Missing traces:
                <br/>
                { missing.length }
            </div>
        </div>
    )
}
import './TraceSideBar.css'
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { createTrace, submitExercise } from "../queries";
import { useQueryClient } from "@tanstack/react-query";

export default function TraceSideBar({
                                         selectedUserStory,
                                         sourceArtefacts,
                                         selectedSourceArtefacts,
                                         setSelectedSourceArtefacts,
                                         selectedTargetArtefact,
                                         setSelectedTargetArtefact,
                                         onCreate = null,
                                         showSubmitButton = true,
                                     }) {
    const { exerciseId, attemptId } = useParams()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    function getSourceArtefact(id) {
        const us = sourceArtefacts.filter(us => us.eUserStoryFragments.map(f => f.id).includes(id))
        const fragments = us[0].eUserStoryFragments
        return fragments.find(f => f.id === id)
    }

    async function onCreateTrace() {
        if (onCreate) {
            onCreate()
            return
        }
        const body = {
            targetArtefactId: selectedTargetArtefact.id,
            sourceArtefactIds: selectedSourceArtefacts,
        }
        await createTrace(body, exerciseId, attemptId)
        await queryClient.invalidateQueries({ queryKey: [`exercise-traces`, exerciseId, attemptId] })
        setSelectedSourceArtefacts([])
        setSelectedTargetArtefact(undefined)
    }

    async function onSubmitExercise() {
        await submitExercise(exerciseId, attemptId)
        navigate('/exercise')
    }

    return (
        <div className='trace-side-bar-container'>
            <div className='trace-side-bar-artefacts'>
                <div className="selected-sources">
                    <h3>Selected Source Artefacts</h3>
                    { selectedSourceArtefacts.length > 0 ?
                        <>
                            <ul>
                                { selectedSourceArtefacts.sort().map(getSourceArtefact).map(item => (
                                    <li>"{ item.content }"</li>
                                )) }
                            </ul>
                            <div>from US{ selectedUserStory.id }</div>
                        </> :
                        <div>No source artefacts selected</div>
                    }
                </div>
                <div className="selected-targets">
                    <h3>Selected Target Artefact</h3>
                    { selectedTargetArtefact ?
                        <div className='selected-artefact-detail'>
                            { selectedTargetArtefact.type } "{ selectedTargetArtefact.name }"
                        </div> :
                        <div>No target artefact selected</div>
                    }
                </div>
            </div>
            <div className='trace-side-bar-button-group'>
                <button className='save-trace-button'
                        onClick={ onCreateTrace }
                        disabled={ (selectedSourceArtefacts.length === 0) || !selectedTargetArtefact }
                >Save Trace
                </button>
                { showSubmitButton &&
                    <button className='submit-exercise-trace-button'
                            onClick={ onSubmitExercise }
                    >Submit Exercise
                    </button>
                }
            </div>
        </div>
    )
}
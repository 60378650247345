import * as d3 from "d3";
import { dragEnded, dragged, dragStarted, handleZoom } from "../../d3Util";

export function buildClassDiagram(
    ref,
    nodes,
    links,
    entityColors,
    selectedArtefact = null,
    setSelectedArtefact = (artefact) => null,
    addInteractivity = true,
    isMarked = (artefact) => false,
    width = 1920,
    height = 1080,
    classWidth = 60,
    classHeight = 30,
) {
    function ticked() {
        link
            .attr('x1', d => d.source.x)
            .attr('y1', d => d.source.y)
            .attr('x2', d => d.target.x)
            .attr('y2', d => d.target.y)

        node
            .attr('x', d => d.x - (classWidth / 2))
            .attr('y', d => d.y - (classHeight / 2))

        text
            .attr('x', d => d.x - (classWidth / 2))
            .attr('y', d => d.y - (classHeight / 2))
    }

    const simulation = d3.forceSimulation(nodes)
        .force('link', d3.forceLink(links).id(d => d.id).distance(160))
        .force('charge', d3.forceManyBody())
        .force('center', d3.forceCenter(width / 2, height / 2))
        .on('tick', ticked)

    d3.select(ref.current)
        .selectAll('*')
        .remove()

    const svg = d3.select(ref.current)
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', [0, 0, width, height])
        .on('contextmenu', (e) => e.preventDefault())

    const link = svg.append('g')
        .attr('stroke-opacity', 1)
        .selectAll('line')
        .data(links)
        .join('line')
        .attr('stroke', d => d.id === selectedArtefact ? '#b62c2c' : isMarked(d) ? '#c8c8c8' : '#000')
        .attr('stroke-width', 4)
        .on('click', (e, node) => setSelectedArtefact(node))

    const nodeGroup = svg.append('g')
        .selectAll('g')
        .data(nodes)
        .enter()
        .append('g')
        .on('click', (e, node) => setSelectedArtefact(node))

    const node = nodeGroup
        .append('rect')
        .attr('height', classHeight)
        .attr('width', classWidth)
        .attr('stroke', d => entityColors[d.type])
        .attr('stroke-width', 2)
        .attr('fill', d => d.id === selectedArtefact ? '#ffc2c2' : isMarked(d) ? '#c8c8c8' : '#fff')

    const text = nodeGroup
        .append('text')
        .text(d => d.name)
        .attr('font-size', '10px')
        .attr('font-weight', 'lighter')
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('dx', 30)
        .attr('dy', 15)
        .attr('stroke', '#000')

    if (addInteractivity) {
        svg.call(d3.zoom()
            .on('zoom', handleZoom))

        nodeGroup.call(d3.drag()
            .on('start', (e) => dragStarted(e, simulation))
            .on('drag', dragged)
            .on('end', (e) => dragEnded(e, simulation)))
    }
}
import NavBar from "../navigation/NavBar";
import React from "react";
import './ExerciseView.css'
import { useCompletedExercises, useCreatedExercises, useCurrentExercises } from "./queries";
import ExerciseListItem from "./ExerciseListItem";
import { ExerciseStatus } from "./Types";

export default function ExerciseView({}) {
    const currentExercises = useCurrentExercises();
    const completedExercises = useCompletedExercises();
    const createdExercises = useCreatedExercises();


    return (<>
        <NavBar account={ true } projects={ false } projectsSettings={ false } projects={ true }/>
        <div className='exercise-container'>
            <div className="current-exercises">
                <h2>Exercises</h2>
                <div className='exercise-list'>
                    { currentExercises.data && currentExercises.data.map(exercise => (
                        <ExerciseListItem exercise={ exercise } status={ ExerciseStatus.CURRENT }/>)) }
                </div>
            </div>
            <div className="completed-exercises">
                <h2>Results</h2>
                <div className='exercise-list'>
                    { completedExercises.data && completedExercises.data.map(exercise => (
                        <ExerciseListItem exercise={ exercise } status={ ExerciseStatus.COMPLETED }/>)) }
                </div>
            </div>
            <div className="created-exercises">
                <h2>Submissions</h2>
                <div className='exercise-list'>
                    { createdExercises.data && createdExercises.data.map(exercise => (
                        <ExerciseListItem exercise={ exercise } status={ ExerciseStatus.CREATED }/>
                    )) }
                </div>
            </div>
            <div className="exercise-tutorial">
                <a href='/exercise/tutorial' className="tutorial-link">
                    Go To Tutorial
                </a>
            </div>
        </div>
    </>)
}
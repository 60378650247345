import React from 'react'
import { Button, Dropdown, Nav, Navbar } from 'react-bootstrap'
import logo from '../../assets/Logo_PA_400x400.png'
import './NavBar.css'
import { BoxArrowRight, Files, Funnel, Gear, Kanban, PersonFill, ListTask } from 'react-bootstrap-icons'
import { useNavigate, useParams } from 'react-router-dom'
import { logoutUser } from 'model/user/user.action'
import store from '../../store'
import Avatar from '../accounting/Avatar'
import { USER_LOCAL_STORAGE } from '../../constants'


let NavBar = ({ account, projects, projectsSettings, productBacklog, filtering, openFilter }) => {
    const navigate = useNavigate()
    const projectId = useParams()
    const userObject = JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE))
    const NavAvatar = React.forwardRef(({ children, onClick }, ref) => (
        <a href="" ref={ ref }
           onClick={ (e) => {
               e.preventDefault()
               onClick(e)
           } } alignright="true">
            <Avatar
                name={ userObject.firstname.charAt(0) + userObject.lastname.charAt(0) }
                size={ '40px' }
                fontsize={ '20px' }
                border={ '#989797 solid 3px' }/>
        </a>
    ))

    return (
        <Navbar bg="black" expand="lg" variant="dark">
            <Navbar.Brand className={ 'navbar-brand' } onClick={ () => navigate('/project') }><img width="48px"
                                                                                                   height="48px"
                                                                                                   src={ logo }
                                                                                                   alt="Logo_PA_400x400"/>{ '   ' }STORYSCREEN</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Nav className={ 'mr-auto' } as="ul">
                <Nav.Link href="https://github.zhaw.ch/schinsev/PA20_ruiz_02/tree/master">
                    GitHub
                </Nav.Link>
            </Nav>
            <Navbar.Collapse>
                <Nav className="justify-content-end" style={ { width: '100%', paddingRight: '20px' } }>
                    { filtering === true &&
                        <Nav.Item>
                            <Button className={ 'p-0' } variant="greenColor" onClick={ openFilter }>
                                <div className={ 'iconBlock' }>
                                    <Funnel/>
                                    <p className={ 'iconName' }>Filter</p>
                                </div>
                            </Button>
                        </Nav.Item> }
                    { productBacklog === true &&
                        <Nav.Item>
                            <Button className={ 'p-0' } variant="greenColor"
                                    onClick={ () => navigate(`/project/${ projectId.projectId }`, { state: 'backToBacklog' }) }>
                                <div className={ 'iconBlock' }>
                                    <Kanban/>
                                    <p className={ 'iconName' }>Backlog</p>
                                </div>
                            </Button>
                        </Nav.Item> }
                    { projectsSettings === true &&
                        <Nav.Item>
                            <Button className={ 'p-0' } variant="roll"
                                    onClick={ () => navigate(`/project/${ projectId.projectId }/settings`, { relative: 'path' }) }>
                                <div className={ 'iconBlock' }>
                                    <div className={ 'gear' }>
                                        <Gear/>
                                    </div>
                                    <p className={ 'iconName' }>Settings</p>
                                </div>
                            </Button>
                        </Nav.Item> }
                    { projects === true &&
                        <Nav.Item>
                            <Button className={ 'p-0' } variant="greenColor"
                                    onClick={ () => navigate('/project') }>
                                <div className={ 'iconBlock' }>
                                    <Files/>
                                    <p className={ 'iconName' }>Projects</p>
                                </div>
                            </Button>
                        </Nav.Item> }
                    <Nav.Item>
                        <Button className={ 'p-0' } variant="greenColor"
                                onClick={ () => navigate('/exercise') }>
                            <div className={ 'iconBlock' }>
                                <ListTask/>
                                <p className={ 'iconName' }>Exercises</p>
                            </div>
                        </Button>
                    </Nav.Item>
                    <Nav.Item className={ 'navItemPosition' }>
                        <Dropdown alignRight>
                            <Dropdown.Toggle as={ NavAvatar }/>
                            <Dropdown.Menu className="accountDropDown" alignRight>
                                { account === true && (<>
                                        <Dropdown.Item className="dropDownItem"
                                                       onClick={ () => navigate('/account') }>
                                            <PersonFill/> Account</Dropdown.Item>
                                    </>
                                ) }
                                <Dropdown.Item className="dropDownItem"
                                               onClick={ () => {
                                                   store.dispatch(logoutUser())
                                                   navigate('/account')
                                               } }><BoxArrowRight/> Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavBar

import './ExerciseAttemptContent.css'
import React, { useEffect, useRef } from "react";
import { buildClassDiagram } from "../../tracing/visualizations/classDiagramView/buildClassDiagram";
import { entityColors } from "../../tutorial/tutorialData";
import { EntityType } from "../../tracing/Types";
import SubArtefactPicker from "../../tutorial/SubArtefactPicker";

export default function ExerciseAttemptContent({
                                                   selectedUserStory,
                                                   selectedSourceArtefacts,
                                                   setSelectedSourceArtefacts,
                                                   targetArtefacts,
                                                   selectedTargetArtefact,
                                                   setSelectedTargetArtefact,
                                                   traces
                                               }) {
    const targetRef = useRef(null);

    useEffect(() => {
        const selectedId = selectedTargetArtefact ? selectedTargetArtefact.id : null
        buildClassDiagram(targetRef, getNodes(), getLinks(), entityColors, selectedId, onClassDiagramClick, true, isTraced, 800, 400)
    }, [selectedTargetArtefact])

    function onClassDiagramClick(item) {
        setSelectedTargetArtefact(item)
    }

    function isTraced(item) {
        return traces.map(t => t.targetArtefactId).includes(item.id)
    }

    function getNodes() {
        return targetArtefacts.filter(item => item.type === EntityType.CLASS || item.type === EntityType.ACTOR)
    }

    function getLinks() {
        return targetArtefacts.map(item => item.eRelationshipEntity)
            .filter(item => item !== null)
            .map(item => {
                return {
                    id: item.id,
                    source: item.from,
                    target: item.to
                }
            })
    }

    return (
        <div className='exercise-content'>
            <div className='target-content'>
                <svg style={ { height: '100%' } } ref={ targetRef } className="target-domain-model"></svg>
            </div>
            <div className='source-content'>
                { selectedUserStory &&
                    <SubArtefactPicker
                        subArtefacts={ selectedUserStory.eUserStoryFragments }
                        entities={ targetArtefacts }
                        entityColors={ entityColors }
                        userStoryId={ selectedUserStory.id }
                        selectableArtefacts={ [] }
                        selectedArtefacts={ selectedSourceArtefacts }
                        setSelectedArtefacts={ setSelectedSourceArtefacts }
                        traces={ traces }
                        allSelectable={ true }
                        getContent={ (fragment) => fragment.content }
                    />
                }
            </div>
        </div>
    )
}
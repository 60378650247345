import React from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { ExerciseStatus } from "./Types";
import { useNavigate } from "react-router-dom";
import { createExerciseAttempt } from "./queries";


/**
 *
 * @param exercise
 * @param status { ExerciseStatus }
 * @returns {JSX.Element}
 * @constructor
 */
export default function ExerciseListItem({ exercise, status }) {
    const navigate = useNavigate();

    function getStatus() {
        if (status === ExerciseStatus.CURRENT) {
            if (exercise.attemptId) return 'in-progress'
            return 'not-started'
        }
        return 'completed'
    }

    function getLink() {
        switch (status) {
            case ExerciseStatus.CURRENT:
                return `/exercise/${ exercise.id }/attempt/${ exercise.attemptId }`
            case ExerciseStatus.CREATED:
                return `/exercise/${ exercise.id }/results`
            default:
                return `/exercise/${ exercise.id }/result/${ exercise.eExerciseAttempts[0].id }`
        }
    }

    async function startExercise() {
        const attempt = await createExerciseAttempt(exercise.id)
        navigate(`./${ exercise.id }/attempt/${ attempt.id }`)
    }

    return (
        <a href={ getStatus() !== 'not-started' && getLink() }
           onClick={ getStatus() === 'not-started' ? startExercise : null }
           className="exercise-item-content">
            <div>
                <h4>{ exercise.name ? exercise.name : exercise.eExercise.name }</h4>
                <div className={ `exercise-status ${ getStatus() }` }>
                    { getStatus() === 'in-progress' ? 'Continue' : (
                        getStatus() === 'completed' ? 'See results' : 'Start'
                    ) }
                </div>
            </div>
            <div>
                <ChevronRight/>
            </div>
        </a>
    )
}